<template>
	<S2SForm title="Client Types (Tags)">
		<v-layout justify-end>
			<v-flex xs12 sm6 class="mb-2">
				<v-text-field label="Filter Client Type" v-model="search" clearable></v-text-field>
			</v-flex>
		</v-layout>
		<v-data-table
			:headers="clientHeaders"
			:items="allClientTypes"
			class="elevation-1"
			:options.sync="pagination"
			:search="search"
			:loading="status.loading"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-dialog v-model="dialog" max-width="500px">
						<template v-slot:activator="{ on }">
							<v-spacer></v-spacer>
							<v-btn color="accent" class="mb-2" v-on="on">
								Add Client Type
							</v-btn>
						</template>
					</v-dialog>
				</v-toolbar>
			</template>

			<template v-slot:item.description="props">
				<v-edit-dialog :return-value.sync="props.item.description" large lazy @save="onUpdateClientType(props.item)" save-text="Update">
					<div>{{ props.item.description }}</div>
					<div slot="input" class="mt-3 title">Update Client Type</div>
					<v-text-field slot="input" v-model="props.item.description" label="Edit" single-line autofocus></v-text-field>
				</v-edit-dialog>
			</template>
		</v-data-table>

		<v-dialog v-model="dialog" persistent max-width="500px" transition="dialog-transition">
			<v-card>
				<v-toolbar>
					<v-toolbar-title>New Client Type</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
				<v-card-text class="pt-3">
					<v-text-field v-model="model.description" label="Description"></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="dialog = false" text>
						Cancel
					</v-btn>
					<v-btn color="accent" @click="onSave()">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</S2SForm>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	name: "ViewClientTypes",

	computed: {
		allClientTypes: function() {
			return this.$store.state.safes.allClientTypes;
		},
		status: function() {
			return this.$store.state.safes.status;
		}
	},

	data: function() {
		return {
			model: {},
			dialog: false,

			pagination: {
				descending: true,
				itemsPerPage: 10
			},

			search: "",

			clientHeaders: [{ text: "Client Type", value: "description" }]
		};
	},

	mounted() {
		this.$store.dispatch("safes/fetchClientTypes");
	},

	methods: {
		onSave() {
			this.$store.dispatch("safes/createClientTypes", this.model);
			this.dialog = false;
		},
		onUpdateClientType(clientType) {
			this.$store.dispatch("safes/updateClientType", clientType);
		}
	}
});
</script>

<style scoped></style>
